import React from 'react'
import './SuccessStories.css'
import { Banner } from '../components/Banner';

export const SuccessStories = () => {
  
  return (
    <div>
      <Banner quote={<p>Explore the remarkable stories of transformation shared by our valued clients at Get Fit Mantra.</p>} title={"SUCCESS STORIES"}/>
      <div>
      <div  className="outerdiv">
    <div className="innerdiv">
      {/* <!-- div1 --> */}
      <div className="div1 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            {/* <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg" alt=""/> */}
          </div>
          <div className="detbox">
            <p className="name">-Vinu J</p>
            {/* <p className="designation">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review">
          <h4>Awesome Experience. I honestly feel I got every penny’s worth.</h4>
        <p>“Hi all. Just wanted to share my insights on my success with this program in the last 30days. Lost 13 pounds so far. It's crucial to gain the knowledge about proper dietary habits to embrace it as a proper lifestyle change. Portion sizing, the right ingredients, and of course, timing of meals is very essential component. Thank you Vaishali & Mithlesh for your guidance throughtout my journey.”</p>
        </div>
      </div>
      {/* <!-- div2 --> */}
      <div className="div2 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            {/* <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg" alt=""/> */}
          </div>
          <div className="detbox">
            <p className="name">-Anil Reddy</p>
            {/* <p className="designation">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review">
          <h4>The team was very supportive and kept me motivated</h4>
        <p>"I successfully completed Get Fit Mantra's 5-day detox program feeling energized and without food cravings. Thanks to Vaishali for her motivation and guidance. Lost 6lbs, weighing 193lbs from 199lbs."</p>
        </div>
      </div>
      {/* <!-- div3 --> */}
      <div className="div3 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            {/* <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg" alt=""/> */}
          </div>
          <div className="detbox">
            <p className="name dark">-Rajni</p>
            {/* <p className="designation dark">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review dark">
          <h4 id='div3_color'>Such a life-changing experience. Highly recommended!</h4>
          <p>“Thank you all so much, my heartful sincere thanks to our one and only Vaishali, when i started this program I was effected by covid,then Dushera and so many events in which I was lost. Vaishali had been constantly guiding supportive. I have not only lost weight or looking good but I have developed lot of confidence, I am totally a different person now.”</p>
        </div>
      </div>
      {/* <!-- div4 --> */}
      <div className="div4 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            {/* <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg" alt=""/> */}
          </div>
          <div className="detbox">
            <p className="name dark">-Kamlesh Vani</p>
            {/* <p className="designation dark">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review dark">
          <h4 id='div4_color'>An overall wonderful and rewarding experience</h4>
        <p>“My experience with Get Fit Mantra was wonderful. Their expertise in living a helathy life helped me a lot”</p>
        </div>
      </div>
      {/* <!-- div5 --> */}
      <div className="div5 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            {/* <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg" alt=""/> */}
          </div>
          <div className="detbox">
            <p className="name">-Shaji</p>
            {/* <p className="designation">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review">
          <h4>Getting guidance from them and learning from their experiences was easy.</h4>
        <p>“Thank you Vaishali for the program and support. This is my first time trying a weight loss program and it helped me tremendously and I feel good about it. It was a challenge initially but Fenobin and I pushed through it and we diligently followed it except for 2-3 meals in 30 days Hope we continue to maintain it. Thank you all again !!”</p>
        </div>
      </div>
    </div>
  </div>
      </div>
    </div>
  )
}
