// Navbar.js

import React, { useState } from 'react';
import "./Navbar.css";// Import your CSS file
// Import your logo image
import logo2 from '../assests/images/GFM_LOGO_2_final.png'

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="logo">
        <img src={logo2} alt="Your Logo" />
      </div>
      <div id='mobile' className={`links ${isMobileMenuOpen ? 'open' : ''}`}>
        <a href="/">HOME</a>
        <a href="ourPrograms">OUR PROGRAMS</a>
        <a href="aboutUs">ABOUT US</a>
        <a href="recepies">RECIPES</a>
        <a href="successStories">SUCCESS STORIES</a>
        <a href="faq">FAQ</a>
        <a href="contactUs">CONTACT US</a>
      </div>
      <div className="hamburger" onClick={toggleMobileMenu}>
        &#9776;
      </div>
    </div>
  );
};

export default Navbar;
