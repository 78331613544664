import React from 'react'
import './RecepieCard.css'

export const RecepieCard = ({imgSrc, recepieName, recepieDesc, link_to}) => {
  return (
    <div class="recipe-card">
        <img src={imgSrc} alt="Food Image" class="recipe-image"/>
        <div class="recipe-details">
            <h2 class="recipe-name"><a href={link_to}>{recepieName}</a></h2>
            <p class="recipe-description">{recepieDesc}</p>
        </div>
    </div>

  )
}
