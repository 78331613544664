import React from "react";
import './Footer.css'
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YoutubeIcon from '@mui/icons-material/YouTube'
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
return(
    <>
    <footer>
    <div className="up">
            <h4>Follow Us</h4>
            <div className="icon">
                <a href="mailto: getfitmantra.info@gmail.com"><EmailIcon/></a>
                <a href="https://www.facebook.com/profile.php?id=100090264160311"><FacebookIcon/></a>
                <a href="#"><TwitterIcon/></a>
                <a href="https://www.instagram.com/getfitmantra_vaishali?igsh=NDZvYjF6ZTVkMzBl"><InstagramIcon/></a>
                <a href="https://www.youtube.com/@GetFitMantra"><YoutubeIcon/></a>
            </div>
    </div>
        <div className="down">
            <h4>Quick Links</h4>
            <ul className="foot-list">
            <a href="/">HOME</a>
            <a href="ourPrograms">OUR PROGRAMS</a>
            <a href="aboutUs">ABOUT US</a>
            <a href="recepies">RECIPES</a>
            <a href="successStories">SUCCESS STORIES</a>
            <a href="faq">FAQ</a>
            <a href="contactUs">CONTACT US</a>
            </ul>
            <p className="trademark">© 2024 - Get Fit Mantra. All Rights Reserved.</p>
        </div>   
    </footer>
    </>
)
}
export default Footer;