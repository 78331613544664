import React from 'react'
import './Banner.css'
export const Banner = ({title, quote}) => {
  return (
    <div className='banner_body'>
        <div className='page_title'>
            <h1>{title}</h1>
            {/* <h3>HOME / {title}</h3> */}
        </div>
        <div className='quote'>{quote}</div>
    </div>
  )
}
