import './Recepie1.css'

import React from 'react'
import Dosa from '../assests/images/recepie_new_images/Panner Dosa.png'

export const Recepie9 = () => {
    return (
        <>
            <section className='Recepie_body'>
                <h1 className='Recepie_title'>RED LENTIL PANEER DOSA</h1>
                <p className='Recepie_intro'>Add this taste of South India into your diet and serve it as a breakfast or lunch or dinner.<strong>Serves 1</strong></p>
                <div className='Recepie_content'>
                    <div className='Recepie_img_part'>
                        <img className='Recepie_img' alt='none' src={Dosa} />
                        <div className='Recepie_ingredients'>
                            <h2>INGREDIENT</h2>
                            <div className='Recepie_ingredient_main'>
                                {/* <h2>Pancakes</h2> */}
                                <ul>
                                    <li>1 bowl red lentils (soaked for at least
                                        1.5 hours)</li>
                                    <li>1 onion, finely chopped</li>
                                    <li>100g paneer or tofu, cut into cubes or
                                        mashed</li>
                                    <li>50g baby spinach</li>
                                    <li>100g mushrooms, sliced</li>
                                    <li>50g capsicum, diced</li>
                                    <li>Pink salt, to taste</li>
                                    <li>Lal mirch (paprika), to taste</li>
                                    <li>Garam masala, to taste
                                    </li>
                                    <li>Roasted cumin seed powder, to taste</li>
                                </ul>
                            </div>
                            {/* <div className='Recepie_ingredient_optional'>
                                <h2>For serving, optional</h2>
                                <ul>
                                    <li>Blueberry compote</li>
                                    <li>Sliced bananas, sliced strawberries or raspberries</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className='Recepie_non_img_part'>
                        <div className='Recepie_instructions'>
                            <h2>INSTRUCTIONS</h2>
                            <ol>
                                <li>Start by grinding the soaked red lentils into a
                                    fine paste using a mixer or blender. Add salt,
                                    lal mirch, and roasted cumin seed powder
                                    according to your taste preferences. Mix well
                                    and set the batter aside.</li>
                                <li>Heat a non-stick pan and sauté the finely
                                    chopped onion, baby spinach, mushrooms,
                                    and capsicum until they are cooked but still
                                    retain a slight crunch. Season the vegetables
                                    with salt, garam masala, and any other
                                    approved spices you desire. Stir well and cook
                                    for an additional 5-10 minutes until the flavors
                                    meld together. The dosa filling is now ready.
                                </li>
                                <li>Heat a non-stick flat surface pan or dosa tawa
                                    over medium heat. Take a ladleful of the
                                    prepared lentil batter and pour it onto the hot
                                    pan. Use the back of the ladle or a spoon to
                                    spread the batter in a circular motion, forming
                                    a thin dosa. Cook it for about 5-10 minutes on
                                    each side until both sides are cooked and turn
                                    golden brown.</li>
                                <li>Once the dosa is cooked, transfer it to a plate.</li>
                                <li>Place a portion of the prepared vegetable
                                    filling onto one half of the dosa. Fold the other
                                    half over the filling to create a half-moon
                                    shape.</li>
                                <li>Cut the dosa into two pieces and serve hot
                                    with homemade mint chutney or any other
                                    approved healthy dip of your choice.</li>
                            </ol>
                        </div>
                        {/* <div className='Recepie_notes'>
                            <h2>Notes</h2>
                            <ul>
                                <li>Double the batch and freeze leftovers for a festive pancake breakfast any day of the week.</li>
                            </ul>
                        </div> */}
                        {/* <div className='Recepie_2'>
                            <h2 className='Recepie_2_name'>Blueberry compote</h2>
                            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
                            <div className='Recepie_2_ingredient'>
                                <h2>INGREDIENTS</h2>
                                <ul>
                                    <li>3 cups frozen blueberries</li>
                                    <li>2 tbsp lemon juice</li>
                                    <li>½ tsp vanilla extract</li>
                                </ul>
                            </div>
                            <div className="Recepie_2_instruction">
                                <h2>INSTRUCTIONS</h2>
                                <ol>
                                    <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                                    <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                                    <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                                    <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                                    <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
                                </ol>
                            </div>
                            <div className="Recepie_2_notes">
                                <h2>NOTES</h2>
                                <ul>
                                    <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
