import React from 'react'
import './Recepies.css'
import { Banner } from '../components/Banner';
import { RecepieCard } from '../components/RecepieCard';
// import Gnocchi from '../assests/images/recepie_images/white bean & sun-dried tomato gnocchi out.jpg'
// import Hoagies from '../assests/images/recepie_images/vegetarian italian hoagies out.jpg'
// import Wraps from '../assests/images/recepie_images/paneer kabab out.png'
// import Pasta from '../assests/images/recepie_images/one-pot tomato basil pasta out.jpg'
// import Sandwiche from '../assests/images/recepie_images/veggie & hummus sandwich out.jpg'
// import Smoothie from '../assests/images/recepie_images/smoothie out.jpg'
// import Bowl from '../assests/images/recepie_images/berry almond smootie Bowl out.jpg'
// import Soup from '../assests/images/recepie_images/cabbage soup out.jpg'
// import Milk from '../assests/images/recepie_images/coco out.jpg'
import Pancakes from '../assests/images/recepie_new_images/recepie1_img.png'
import TofuTacos from '../assests/images/recepie_new_images/Scrumbled tofus breakfast tacos.png'
import Oatmeal from '../assests/images/recepie_new_images/Strawberry banana oatmeal.png'
import Quesadillas from '../assests/images/recepie_new_images/SWEET POTATO & BLACK BEAN QUESADILLAS.png'
import Sandwich from '../assests/images/recepie_new_images/LOADED VEGGIE SANDWICH.png'
import VeggieStirFry from '../assests/images/recepie_new_images/EASY VEGGIE STIR FRY.png'
import CabbageSoup from '../assests/images/recepie_new_images/cabbage soup out.jpg'
import kebabs from '../assests/images/recepie_new_images/Indian Style Kebabs.png'
import Dosa from '../assests/images/recepie_new_images/Panner Dosa.png'

export const Recepies = () => {
  return (
    <div>
      <Banner quote={<p>Get started today to lose weight fast, improve your health and have more energy</p>} title={"RECIPES"}/>
      <div className='recepie_container'>
        <RecepieCard imgSrc={Pancakes}  link_to={'recepie1'} recepieName={"PANCAKES WITH BLUEBERRY COMPOTE"} recepieDesc={"The fluffiest pancakes you will ever make! These pancakes are naturally..."}/>
        <RecepieCard imgSrc={TofuTacos} link_to={'recepie2'} recepieName={"SCRAMBLED TOFU BREAKFAST TACOS"} recepieDesc={"Scrambled tofu loaded with savory spices and fresh vegetable makes for a delicious..."}/>
        <RecepieCard imgSrc={Oatmeal} link_to={'recepie3'} recepieName={"STRAWBERRY BANANA OATMEAL"} recepieDesc={"The ultimate healthy and easy breakfast! Creamy, naturally sweet, and fiber-rich, this oatmeal will keep..."}/>
        <RecepieCard imgSrc={Quesadillas} link_to={'recepie4'} recepieName={"SWEET POTATO & BLACK BEAN QUESADILLAS"} recepieDesc={"Perfectly seasoned, simple to prepare, and absolutely delicious. These quesadillas are protein..."}/>
        <RecepieCard imgSrc={Sandwich} link_to={'recepie5'} recepieName={"LOADED VEGGIE SANDWICH"} recepieDesc={"The best loaded sandwich made with tomatoes, cucumbers, carrots and sprouts, layered between creamy..."}/>
        <RecepieCard imgSrc={VeggieStirFry} link_to={'recepie6'} recepieName={"EASY VEGGIE STIR FRY"} recepieDesc={"Nutrient-rich stir fry made with colorful vegetables and protein-packed tempeh. This dish comes together quickly ..."}/>
        <RecepieCard imgSrc={CabbageSoup} link_to={'recepie7'} recepieName={"VEGGIE CABBAGE SOUP"} recepieDesc={"Loaded with cabbage, carrots, bell pepper and tomato, this healthy cabbage soup..."}/>
        <RecepieCard imgSrc={kebabs} link_to={'recepie8'} recepieName={"INDIAN STYLE KEBABS"} recepieDesc={"These healthy Indian-style kebabs blend protein-rich red kidney beans with nutrient-packed spinach, offering a delicious..."}/>
        <RecepieCard imgSrc={Dosa} link_to={'recepie9'} recepieName={"RED LENTIL PANEER DOSA"} recepieDesc={"Add this Taste of South India into Your Diet and Serve it as a Breakfast or Lunch..."}/>
      </div>
    </div>
  )
}
