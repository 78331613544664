import './FormContactUs.css'

import React, { useState } from 'react'

export const FormContactUs = () => {

    const [formData, setFormData] = useState({
        fullName: '',
        contactNumber: '',
        email: '',
        country: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can perform any validation or send the data to your backend
        if(formData.fullName ==='' || formData.contactNumber ==='' || formData.email ==='' || formData.country ==='' || formData.message ==='')
        {
            alert('All the fields are mandatory to submit the form!')
            return
        }
        else{
            const encodedFormData = new URLSearchParams(formData);

            console.log(formData);
            fetch("https://script.google.com/macros/s/AKfycbyzfBwOBSG9QSanUWQf60OQm7_Lxm3H2St8f6BSq1Ujq2AbdtWQwT-RyLvLqCSuIEHi/exec",{
                method: "POST",
                body: encodedFormData
            })
        // Reset the form after submission
        setFormData({
            fullName: '',
            contactNumber: '',
            email: '',
            country: '',
            message: ''
        });
        alert('Form Submitted')
        }
    };


    return (
        <div className='form_body'>
            <div className='form'>
                <h2>Send us a message</h2>
                <form className='details' onSubmit={handleSubmit}>
                        <input placeholder='Full Name' type="text" name="fullName" value={formData.fullName} onChange={handleChange} />
                    <br />
                        <input placeholder='Contact Number' type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} />
                    <br />
                        <input placeholder='Email' type="email" name="email" value={formData.email} onChange={handleChange} />
                    <br />
                        <input placeholder='Country' type="text" name="country" value={formData.country} onChange={handleChange} />
                    <br />
                        <textarea placeholder='What are you Looking For(Weight Loss/ Business)' name="message" value={formData.message} onChange={handleChange} />
                    <br />
                    <p>*By submitting the form you agree to be contacted by the getfitmantra team.</p>
                    <br/>
                    <button className='submit_btn' type="submit">Send Message</button>
                </form>
            </div>
            <div className='form_side'>
                <h2>Contact us</h2>
                <h3>We're open for any suggestion or just have a chat</h3>
                <div className='form_side_item'>
                    <h3 className='h_bold'>Address: </h3>
                    <h3>San antonio, Texas</h3>
                </div>
                <div className='form_side_item'>
                    <h3 className='h_bold'>Email:</h3>
                    <h3>getfitmantra.info@gmail.com</h3>
                </div>
                <div className='form_side_item'>
                    <h3 className='h_bold'>Website :</h3>
                    <h3>GetFitMantra.com</h3>
                </div>
            </div>
        </div>

    )
}
