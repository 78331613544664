import React, { useEffect }from 'react'
import { useState } from 'react'
import './OurPrograms.css'
import { Banner } from '../components/Banner'

export const OurPrograms = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      window.onmessage = function (e) {
        if (e.data.hasOwnProperty("frameHeight"))
          document.getElementById("fitbudd").style.height = "" + e.data["frameHeight"] + "px";
        if (e.data.hasOwnProperty("goto"))
          window.location.href = e.data["goto"];
      };
    `;
    document.body.appendChild(script);
  }, []);

  const [selectedProgram, setSelectedProgram] = useState(program1);

  const toggleContent = (program) => {
    setSelectedProgram(program);
    document.getElementById('our_program_content').scrollIntoView();
  };

  


  return (
    <div>
      <Banner quote={<p>Let's work together to help you attain the lifelong skills you need to make a lasting impact on your health</p>} title={"OUR PROGRAMS"}/>
      {/* <div className='services_body'>
        <div className='navigation_box'>
          <ul className='navigation_list'>
            <li className='program'><a onClick={() =>{toggleContent(program1)}}>GUT HEALTH/DETOX PROGRAM</a></li>
            <li className='program'><a onClick={() =>{toggleContent(program2)}}>INCH LOSS/WEIGHT LOSS PROGRAM</a></li>
            <li className='program'><a onClick={() =>{toggleContent(program3)}}>5-DAY DETOX PROGRAM</a></li>
            <li className='program'><a onClick={() =>{toggleContent(program4)}}>WELLNESS PROGRAM</a></li>
            <li className='program'><a onClick={() =>{toggleContent(program5)}}>WOMEN'S HEALTH PROGRAM</a></li>
            <li className='program'><a onClick={() =>{toggleContent(program6)}}>SKINCARE THERAPY</a></li>
            <li className='program'><a onClick={() =>{toggleContent(program7)}}>COLLAGEN THERAPY</a></li>
          </ul>
        </div>
        <div id='our_program_content' className='service_content'>
          <div className='service_content_title'>
            <h1>{selectedProgram.title}</h1>
          </div>
          <div className='service_content_body'>
            <p>{selectedProgram.description}</p>
          </div>
        </div>
      </div> */}
      <iframe id="fitbudd" src="https://getfitmantra.fitbudd.com/packs?embed=2"
        allowtransparency="true"
        style={{ width: '100%', height: 0, border: 'none' }}>
      </iframe>
    </div>
  )
}



const program1 = {
  title: "GUT HEALTH AND DETOX PROGRAM",
  description: <div>
  <p><h2>&quot;Optimize Your Wellness Journey: A Comprehensive Gut Health and Weight Loss Program&quot;</h2></p>

  <p>Introduction:<br />
  Welcome to our transformative program designed to revitalize your health by focusing on the symbiotic relationship between gut health and weight loss. Explore a holistic approach that integrates science-backed strategies, nutrition, and lifestyle changes for sustainable well-being.</p>
  
  <p>Section 1: Understanding the Gut-Weight Connection<br />
  Uncover the intricate connection between gut health and weight management. Dive into the science behind how a balanced gut microbiome positively influences metabolism, nutrient absorption, and overall health.</p>
  
  <p>Section 2: Customized Nutrition Plans<br />
  Discover personalized nutrition plans tailored to support your gut health and weight loss goals. Our expertly crafted meal plans emphasize nutrient-dense foods, probiotics, and prebiotics to foster a healthy gut environment.</p>
  
  <p>Section 3: Proven Strategies for Gut Health<br />
  Explore evidence-based strategies to promote gut health, including the incorporation of fermented foods, dietary fiber, and gut-friendly supplements. Learn how to create a gut-friendly environment that optimizes digestion and nutrient absorption.</p>
  
  <p>Section 4: Fitness Routines for Weight Loss<br />
  Integrate effective workout routines that complement your gut health journey. From metabolism-boosting exercises to stress-reducing activities, find the perfect balance for achieving and maintaining a healthy weight.</p>
  
  <p>Section 5: Lifestyle Modifications for Long-Term Success<br />
  Unearth practical lifestyle adjustments that contribute to both gut health and sustainable weight loss. From stress management techniques to quality sleep, embrace habits that nurture your overall well-being.</p>
  
  <p>Section 6: Success Stories and Testimonials<br />
  Be inspired by real-life success stories and testimonials from individuals who have transformed their lives through our gut health and weight loss program. Learn from their experiences and discover the potential for your own success.</p>
  
  <p>Conclusion:<br />
  Embark on a transformative journey towards optimal health through our holistic Gut Health and Weight Loss Program. By understanding the profound impact of gut health on weight management, you can make informed choices that lead to sustainable well-being. Embrace the power of balance, nutrition, and lifestyle modifications to achieve lasting results. Start your journey today!</p>
  </div>,
};
const program2 = {
  title: "INCH LOSS/WEIGHT LOSS PROGRAM",
  description: <div>
  <p>When aiming for your fitness goals, the scale number isn't the key factor; what truly matters is your body composition&mdash;the balance between fat and muscle. The Lean Body System offers Burn/Ignite, SHAPE, and Activate, employing innovative technologies to facilitate your body transformation in three crucial ways.</p>

  <p> Prepare for a fresh approach to get fit and lose inches.</p>
  
  <p>Boost metabolism<br />
  Aid natural detoxification<br />
  Enhance body composition<br />
  Eager to begin? It's as easy as &quot;TAKE 3 MAKE 3.</p>
  </div>,
};
const program3 = {
  title: "5-DAY DETOX PROGRAM",
  description: "Experience the 5-Day Drop for a boost before your upcoming vacation, wedding, or to kickstart your transformation goals.With clear guidance on nutrition, expect improved energy without caffeine, reduced bloating, a healthy glow, and weight reduction. Utilize an easy-to-follow guide, join an optional support group, and nourish your body with our powerful blend of Sundance Sea trace minerals, superfoods phytonutrients, probiotics, and digestive enzymes. Witness a decline in water weight, bloating, puffiness, digestive bulk, and fat weight during this effective program. You've made a smart decision with the 5-Day Drop – the perfect choice for your goals.",
};
const program4 = {
  title: "WELLNESS PROGRAM",
  description: <div><p>Our Wellness Program goes beyond traditional fitness regimes by incorporating elements of mindfulness, stress management, and personalized nutrition. We believe that true wellness encompasses physical, mental, and emotional well-being, and our program is designed to address each of these aspects comprehensively.</p>

  <p>Through a combination of personalized coaching, group classes, educational workshops, and lifestyle resources, our program empowers participants to make sustainable lifestyle changes that promote long-term health and vitality. Whether your goals are to lose weight, build strength, reduce stress, or simply improve your overall well-being, our dedicated team of experts is here to support you every step of the way.</p>
  
  <p>Join us on this transformative journey to unlock your full potential and live your healthiest, happiest life. With our Wellness Program, the power to thrive is within your reach.</p></div>,
};
const program5 = {
  title: "WOMEN'S HEALTH PROGRAM",
  description: <div><p><h2>Elevate Your Women's Health with Modere: Embrace Radiant Wellness!</h2></p>

  <p>Attention all phenomenal women: We are here to empower your journey towards vibrant, holistic well-being. Step into a world where you take charge of your health, radiate confidence, and celebrate your inner strength. It's time to prioritize self-care, nourish your body, and radiate with beauty and vitality. Elevate your health, celebrate your femininity, and let us be your partner in embracing the extraordinary woman you are.</p></div>,
};
const program6 = {
  title: "SKINCARE THERAPY",
  description: <div><p><h2>Unlock Your Radiant Beauty: Embrace Your Timeless Glow!</h2></p>

  <p>Indulge in the luxurious world of Skincare and experience a transformation like no other. Reveal your inner radiance as our exquisite products unveil your timeless beauty. Get ready to elevate your skincare routine to new height. Our skincare collection combines scientific innovation with nature's finest ingredients, creating a harmonious fusion of beauty and effectiveness. From cutting-edge formulations to botanical extracts, It delivers results that exceed expectations.<br />
  Say goodbye to fine lines, dullness, and uneven skin tone. Our Skincare Program offers targeted solutions to address the signs of aging, helping you achieve a youthful, vibrant complexion. Rediscover your confidence as you unveil a revitalized, age-defying glow.<br />
  Rediscover the confidence that comes with a luminous complexion and let your skincare journey become an act of self-love.. It's time to elevate your skincare routine and reveal the breathtaking beauty that has always been within you.</p></div>,
};
const program7 = {
  title: "COLLAGEN THERAPY",
  description: <div><p><h2>Ignite Your Beauty: Unveil Your Radiant Transformation!</h2></p>

  <p>Experience the power of youth with Our Collagen, the secret to unlocking your natural beauty. Replenish your skin, revitalize your hair, and rejuvenate your body from within. Get ready to witness a radiant transformation like never before!<br />
  Modere Collagen nourishes your body at the core, promoting healthy skin, luscious hair, and strong nails. Let your inner radiance shine through as you embrace the transformative power of collagen.<br />
  Turn back the hands of time and reduce the visible signs of aging. Modere Collagen supports elasticity, plumps the skin, and diminishes the appearance of fine lines and wrinkles, revealing a youthful, smooth complexion.</p>
  
  <p>Collagen is not just for beauty; it supports joint health too! Collagen provides the building blocks for healthy joints, reducing discomfort and supporting flexibility. Move with grace and embrace an active lifestyle.<br />
  It is more than a beauty supplement; it's a holistic approach to well-being. As you nurture your body with this essential protein, you'll experience a renewed sense of vitality and overall wellness.<br />
  Let your skin glow, your hair dazzle, and your confidence soar. It's time to ignite your beauty and embrace a more vibrant, youthful you. Experience the power of Collagen today!</p></div>,
};

