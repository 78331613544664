import './Recepie1.css'

import React from 'react'
import RecepieImg from '../assests/images/recepie_new_images/recepie1_img.png'

export const Recepie1 = () => {
  return (
    <>
      <section className='Recepie_body'>
      <h1 className='Recepie_title'>FlUFFY WHOLE GRAIN PANCAKES WITH BLUEBERRY COMPOTE</h1>
      <p className='Recepie_intro'>The fluffiest pancakes you will ever make! These pancakes are naturally sweetened, made right in the blender,freezer friendly and delicious served with blueberry-lemon compote or your favorite fresh fruit.<strong>Serves 2</strong></p>
      <div className='Recepie_content'>
        <div className='Recepie_img_part'>
          <img className='Recepie_img' alt='none' src={RecepieImg}/>
          <div className='Recepie_ingredients'>
            <h2>INGREDIENT</h2>
            <div className='Recepie_ingredient_main'>
              <h2>Pancakes</h2>
              <ul>
                <li>1 large very ripe banana (brown and spotty)</li>
                <li>1 cup roiled oats</li>
                <li>½ tsp cinnamon, optional</li>
                <li>1 cup unsweetened non-dairy milk</li>
                <li>1 tsp vanilla extract</li>
                <li>1 tsp baking powder</li>
                <li>1 tsp apple cider vinegar</li>
                <li>pinch of salt</li>
              </ul>
            </div>
            <div className='Recepie_ingredient_optional'>
              <h2>For serving, optional</h2>
              <ul>
                <li>Blueberry compote</li>
                <li>Sliced bananas, sliced strawberries or raspberries</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='Recepie_non_img_part'>
          <div className='Recepie_instructions'>
            <h2>INSTRUCTIONS</h2>
            <ol>
              <li>Combine all the ingredients in a high powered blender and process until smooth.</li>
              <li>Allow the batter to rest for 5 to 10 minutes.</li>
              <li>Heat a non-stick skillet over medium heat.</li>
              <li>Once hot,scoop about ¼ cup of batter per pancake onto the pan. Cook for 3 to 4 minutes or until the edges start to brown. Flip and cook for another 3 to 4 minutes</li>
              <li>Repeat until all the batter is gone.</li>
              <li>Serve topped with blueberry-lemon compote or fresh-fruit.</li>
            </ol>
          </div>
          <div className='Recepie_notes'>
            <h2>Notes</h2>
            <ul>
              <li>Double the batch and freeze leftovers for a festive pancake breakfast any day of the week.</li>
            </ul>
          </div>
          <div className='Recepie_2'>
            <h2 className='Recepie_2_name'>Blueberry compote</h2>
            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
            <div className='Recepie_2_ingredient'>
              <h2>INGREDIENTS</h2>
              <ul>
                <li>3 cups frozen blueberries</li>
                <li>2 tbsp lemon juice</li>
                <li>½ tsp vanilla extract</li>
              </ul>
            </div>
            <div className="Recepie_2_instruction">
              <h2>INSTRUCTIONS</h2>
              <ol>
                <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
              </ol>
            </div>
            <div className="Recepie_2_notes">
              <h2>NOTES</h2>
              <ul>
                <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  )
}

