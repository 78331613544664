import React from 'react'
import './Profile.css'
import uncleProfile from '../assests/images/vaishali_homeslide.jpg'

export const Profile = ({name, designation, intro, img_src, imgshow_left}) => {
  if(imgshow_left === true)
  {
    return (
      <div className='body_profile'>
          <div className='profile_img'>
            <img src={img_src}/>
          </div>
          <div className='description'>
            <h1 className='name'>{name}</h1>
            <h5>{designation}</h5>
            <p>{intro}</p>
          </div>
      </div>
    )
  }
  return (
    <div className='body_profile'>
        <div className='description'>
          <h1 className='name'>{name}</h1>
          <h5>{designation}</h5>
          <p>{intro}</p>
        </div>
        <div className='profile_img'>
          <img src={img_src}/>
        </div>
    </div>

  )
}
