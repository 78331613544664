import React from "react";
import "./Home.css";
import heroImage from '../assests/images/vaishali_homeslide-ai-brush-removebg-h4dyd88g.png'
import ScrollEffect from "../components/ScrollEffect";
import SectionWithTwoParts from "../components/Cards";
import CardsInfo from "../components/CardsInfo";
import about_img from "../assests/images/Vaishali_Shah_Pfp.jpg";
import { Form_Event } from "../components/Form_Event";
// import { HomeCarosol } from "../components/HomeCarosol";

const Home = () =>{
    return (
        <>
            {/* <HomeCarosol/> */}
            <div className="hero"> 
               <div className="text-section">
                <h1>Transform Today <br /> For <br/> Healthier Tomorrow</h1>
                <p>Healthy Habits | Happy Life | Healthy Body</p>
                <a href="contactUs" className="btn">Enquire Now</a>
               </div>
               
               <img src={heroImage} className="hero-img" />
            </div>
            {/* <div className="event_form">
                <Form_Event/>
            </div> */}
            <div className="programs">
                <SectionWithTwoParts
                 sectionTitle="Tailored weight-loss solutions designed to seamlessly integrate with your unique lifestyle"
                 part1={CardsInfo[0]}
                 part2={CardsInfo[1]} 
                />
                <button className="explore-btn"><a href="ourPrograms">Explore More Programs</a></button>
            </div>
           <div className="small-sec">
            <h2>WHAT MAKES US UNIQUE</h2>
            <p>"GetFitMantra" is a unique choice because it serves as a dedicated platform aimed
                at helping individuals achieve their weight loss goals while also fostering motivation for a healthier lifestyle. This brand provides valuable guidance, personalized support, and expert advice to assist people in their weight reduction journey. More than just a name, "GetFitMantra" embodies the commitment to helping
                individuals attain a healthier, fitter, and more fulfilling life through its 
                services and resources.</p>
           </div>

           <div className="about-sec">
           
            <div className="pic">
                <img src={about_img} />
            </div>
            
            <div className="txt">
              <h2> ABOUT NUTRITIONIST</h2>
                <p>Meet Vaishali Shah, the visionary founder of "Get fit Mantra", a revolutionary Nutrition program that has transformed countless lives. Fueled by her passion for health and well-being, Vaishali embarked on a mission to create a holistic approach to weight management.</p>
                <div className="two-sec">
                    <h3>MY APROACHES</h3>
                    <p>Drawing from her background in nutrition and fitness, Vaishali 's approach goes beyond traditional dieting. Get Fit Mantra emphasizes on root causes of weight gain, personalized lifestyle changes, guided workouts, and mental well-being strategies. What sets Vaishali apart is her commitment to fostering a supportive community, empowering participants to share their journeys and inspire one another.</p>
                    <a href="aboutUs" className="btn">MORE ABOUT ME</a>

                    <h3>GETTING STARTED</h3>
                    <p>You can contact us through email or
                     phone for free counsultation and get onboard.
                    </p>
                    <a href="contactUs" className="btn">GET STARTED</a>
                </div>
            </div>
           </div>
        </>
    )
}
export default Home;