// SectionWithTwoParts.js

import React from 'react';
import './Cards.css';

const SubSection = ({ imageSrc, subheading, description }) => (
  <div className="sub-section">
   <div className='img-container'>
   <img src={imageSrc} alt="Subsection Image" />
   </div>
    <div className='text-part'>
    <h2>{subheading}</h2>
    <p>{description}</p>
    </div>
  </div>
);

const SectionWithTwoParts = ({ sectionTitle, part1, part2 }) => (
  <div className="section">
    <h1>{sectionTitle}</h1>
    <div className="parts-container">
      <SubSection {...part1} />
      <SubSection {...part2} />
    </div>
  </div>
);

export default SectionWithTwoParts;
