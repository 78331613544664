import './Recepie1.css'

import React from 'react'
import Sandwich from '../assests/images/recepie_new_images/LOADED VEGGIE SANDWICH.png'

export const Recepie5 = () => {
    return (
        <>
            <section className='Recepie_body'>
                <h1 className='Recepie_title'>LOADED VEGGIE SANDWICH</h1>
                <p className='Recepie_intro'>The best loaded sandwich made with tomatoes, cucumbers, carrots and sprouts, layered between creamy
                    hummus for a simple and delicious meal. Serve with fresh fruit or additional sliced vegetables for even more
                    plant-power. <strong>Serves 1</strong></p>
                <div className='Recepie_content'>
                    <div className='Recepie_img_part'>
                        <img className='Recepie_img' alt='none' src={Sandwich} />
                        <div className='Recepie_ingredients'>
                            <h2>INGREDIENT</h2>
                            <div className='Recepie_ingredient_main'>
                                {/* <h2>Pancakes</h2> */}
                                <ul>
                                    <li>2 slices whole grain bread</li>
                                    <li>¼ cup hummus</li>
                                    <li>2 slices heirloom tomato</li>
                                    <li>½ cup spinach</li>
                                    <li>¼ cup grated carrot</li>
                                    <li>4 slices cucumber</li>
                                    <li>¼ cup alfalfa sprouts, optional </li>
                                </ul>
                            </div>
                            <div className='Recepie_ingredient_optional'>
                                <h2>For serving, optional</h2>
                                <ul>
                                    <li>Baby carrots, celery sticks, sliced
                                        bell pepper</li>
                                    <li>Apple, peach, melon, grapes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='Recepie_non_img_part'>
                        <div className='Recepie_instructions'>
                            <h2>INSTRUCTIONS</h2>
                            <ol>
                                <li>Prepare all of the veggie fixings. Toast slices of bread
                                    or leave untoasted.</li>
                                <li>Spread a hearty layer of hummus onto each slice of
                                    bread.</li>
                                <li>Top one slice with a layer of tomatoes, spinach, carrots,
                                    cucumbers and alfalfa sprouts, if using. Place the
                                    second slice of bread hummus-side down on top of the
                                    veggie fixings.
                                </li>
                                <li>Slice in half and serve with a side of fruit or sliced
                                    vegetables, if desired.</li>
                            </ol>
                        </div>
                        {/* <div className='Recepie_notes'>
                            <h2>Notes</h2>
                            <ul>
                                <li>Double the batch and freeze leftovers for a festive pancake breakfast any day of the week.</li>
                            </ul>
                        </div> */}
                        {/* <div className='Recepie_2'>
                            <h2 className='Recepie_2_name'>Blueberry compote</h2>
                            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
                            <div className='Recepie_2_ingredient'>
                                <h2>INGREDIENTS</h2>
                                <ul>
                                    <li>3 cups frozen blueberries</li>
                                    <li>2 tbsp lemon juice</li>
                                    <li>½ tsp vanilla extract</li>
                                </ul>
                            </div>
                            <div className="Recepie_2_instruction">
                                <h2>INSTRUCTIONS</h2>
                                <ol>
                                    <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                                    <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                                    <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                                    <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                                    <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
                                </ol>
                            </div>
                            <div className="Recepie_2_notes">
                                <h2>NOTES</h2>
                                <ul>
                                    <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
