import './Recepie1.css'

import React from 'react'
import tacos from '../assests/images/recepie_new_images/Scrumbled tofus breakfast tacos.png'

export const Recepie2 = () => {
    return (
        <>
            <section className='Recepie_body'>
                <h1 className='Recepie_title'>SCRAMBLED TOFU BREAKFAST TACOS</h1>
                <p className='Recepie_intro'>Scrambled tofu loaded with savory spices and fresh vegetables makes for a delicious and hearty breakfast option! Enjoy fresh or make ahead and reheat for an on the go breakfast.<strong>Serves 2</strong></p>
                <div className='Recepie_content'>
                    <div className='Recepie_img_part'>
                        <img className='Recepie_img' alt='none' src={tacos} />
                        <div className='Recepie_ingredients'>
                            <h2>INGREDIENT</h2>
                            <div className='Recepie_ingredient_main'>
                                <h2>Scramble</h2>
                                <ul>
                                    <li>½ package (14-16 oz) organic tofu, extra-firm</li>
                                    <li>2 tbsp of water</li>
                                    <li>½ onion, chopped</li>
                                    <li>1 garlic clove,minced</li>
                                    <li>½ teaspoon chilli powder</li>
                                    <li>½ tsp cumin</li>
                                    <li>½ tsp turmeric</li>
                                    <li>Black pepper to taste</li>
                                    <li>1 tbsp reduced-sodium soy sauce</li>
                                </ul>
                            </div>
                            <div className='Recepie_ingredient_optional'>
                                <h2>For serving</h2>
                                <ul>
                                    <li>½ avocado, sliced</li>
                                    <li>Salsa</li>
                                    <li>Squeeze of time</li>
                                    <li>Corn or whole-grain tortillas</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='Recepie_non_img_part'>
                        <div className='Recepie_instructions'>
                            <h2>INSTRUCTIONS</h2>
                            <ol>
                                <li>Press tofu: Pat tofu dry with paper towels and then roll
                                    in a clean, absorbent towel. Place something heavy on
                                    top, such as a cast-iron skillet, and let sit for 10 to 15
                                    minutes. Chop produce and measure out spices while
                                    you wait.</li>
                                <li>Add 2 tablespoons of water to a large skillet over
                                    high heat. When the water is simmering, add onion
                                    and garlic, stirring often, until onion is soft, about 5
                                    minutes. Add a small amount of additional water if
                                    needed to prevent sticking.
                                </li>
                                <li>Meanwhile, unwrap the tofu and place it on a cutting
                                    board. Use a fork to crumble the tofu into bite-sized
                                    pieces.
                                </li>
                                <li> Add chili powder, cumin, turmeric, and black pepper
                                    and let toast for 30 seconds. Add zucchini and tofu,
                                    reduce heat and cook, stirring often, until zucchini
                                    is tender, about 5 minutes. Add a small amount of
                                    additional water if necessary to prevent sticking.
                                </li>
                                <li>Stir in soy sauce.</li>
                                <li>Serve in corn or whole grain tortillas and topped with
                                    salsa. lime juice and sliced avocado.</li>
                            </ol>
                        </div>
                        {/* <div className='Recepie_notes'>
                            <h2>Notes</h2>
                            <ul>
                                <li>Double the batch and freeze leftovers for a festive pancake breakfast any day of the week.</li>
                            </ul>
                        </div> */}
                        {/* <div className='Recepie_2'>
                            <h2 className='Recepie_2_name'>Blueberry compote</h2>
                            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
                            <div className='Recepie_2_ingredient'>
                                <h2>INGREDIENTS</h2>
                                <ul>
                                    <li>3 cups frozen blueberries</li>
                                    <li>2 tbsp lemon juice</li>
                                    <li>½ tsp vanilla extract</li>
                                </ul>
                            </div>
                            <div className="Recepie_2_instruction">
                                <h2>INSTRUCTIONS</h2>
                                <ol>
                                    <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                                    <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                                    <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                                    <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                                    <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
                                </ol>
                            </div>
                            <div className="Recepie_2_notes">
                                <h2>NOTES</h2>
                                <ul>
                                    <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
