import './Recepie1.css'

import React from 'react'
import Quesadillas from '../assests/images/recepie_new_images/SWEET POTATO & BLACK BEAN QUESADILLAS.png'

export const Recepie4 = () => {
    return (
        <>
            <section className='Recepie_body'>
                <h1 className='Recepie_title'>SWEET POTATO & BLACK BEAN QUESADILLAS</h1>
                <p className='Recepie_intro'>Perfectly seasoned, simple to prepare, and absolutely delicious. These quesadillas are protein-packed, fiber-rich,
                    and the perfect addition to your weeknight rotation.<strong>Serves 2</strong></p>
                <div className='Recepie_content'>
                    <div className='Recepie_img_part'>
                        <img className='Recepie_img' alt='none' src={Quesadillas} />
                        <div className='Recepie_ingredients'>
                            <h2>INGREDIENT</h2>
                            <div className='Recepie_ingredient_main'>
                                {/* <h2>Pancakes</h2> */}
                                <ul>
                                    <li>1 medium sweet potato, cubed</li>
                                    <li>¼ cup of salsa</li>
                                    <li>1 tsp chili powder</li>
                                    <li>1 tsp cumin</li>
                                    <li>½ juice of one lime</li>
                                    <li>2 tbsp nutritional yeast, optional</li>
                                    <li>1 cup cooked black beans, drained
                                        and rinsed</li>
                                    <li>Salt and pepper to taste</li>
                                    <li>4 whole-wheat tortillas </li>
                                </ul>
                            </div>
                            <div className='Recepie_ingredient_optional'>
                                <h2>For serving, optional</h2>
                                <ul>
                                    <li>Guacamole</li>
                                    <li>Salsa</li>
                                    <li>Non-dairy plain yogurt</li>
                                    <li>Sliced bell peppers</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='Recepie_non_img_part'>
                        <div className='Recepie_instructions'>
                            <h2>INSTRUCTIONS</h2>
                            <ol>
                                <li>Place the cubed sweet potatoes in a saucepan with
                                    enough water to cover. Bring to a boil, then reduce
                                    heat, cover and simmer until fork-tender.
                                </li>
                                <li>Transfer cooked sweet potatoes to a mixing bowl and
                                    add salsa, chili powder, lime juice, and nutritional
                                    yeast. With a fork, smash and mix until well-combined.
                                    Fold in black beans. Add salt and pepper to taste.</li>
                                <li>Heat a grill or grill pan over medium heat. Spread a
                                    heaping ½ cup of the sweet potato mixture onto a
                                    tortilla. Top with a second tortilla and gently press it
                                    down. Repeat with remaining tortillas.</li>
                                <li>Place quesadillas on the heated grill and cook for
                                    about 3 minutes on each side, until they are golden
                                    brown and grill marks form.</li>
                                <li>Slice into triangles and serve with guacamole, salsa,
                                    and/or non-dairy plain yogurt for dipping. To round out
                                    the meal, pair with sliced bell peppers.
                                </li>

                            </ol>
                        </div>
                        <div className='Recepie_notes'>
                            <h2>Notes</h2>
                            <ul>
                                <li>If you don’t have a grill, you can make these
                                    quesadillas on a stovetop.
                                </li>
                                <li>Feel free to substitute the boiled sweet potato for an
                                    equal amount of roasted sweet potato.</li>
                            </ul>
                        </div>
                        {/* <div className='Recepie_2'>
                            <h2 className='Recepie_2_name'>Blueberry compote</h2>
                            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
                            <div className='Recepie_2_ingredient'>
                                <h2>INGREDIENTS</h2>
                                <ul>
                                    <li>3 cups frozen blueberries</li>
                                    <li>2 tbsp lemon juice</li>
                                    <li>½ tsp vanilla extract</li>
                                </ul>
                            </div>
                            <div className="Recepie_2_instruction">
                                <h2>INSTRUCTIONS</h2>
                                <ol>
                                    <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                                    <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                                    <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                                    <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                                    <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
                                </ol>
                            </div>
                            <div className="Recepie_2_notes">
                                <h2>NOTES</h2>
                                <ul>
                                    <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
