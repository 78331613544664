import './Recepie1.css'

import React from 'react'
import kebabs from '../assests/images/recepie_new_images/Indian Style Kebabs.png'

export const Recepie8 = () => {
    return (
        <>
            <section className='Recepie_body'>
                <h1 className='Recepie_title'>INDIAN STYLE KEBABS</h1>
                <p className='Recepie_intro'>These healthy Indian-style kebabs blend protein-rich red kidney beans with nutrient-packed spinach, offering a delicious and nutritious option. Packed with fiber, vitamins, and minerals, they support digestive health and provide a flavorful, guilt-free indulgence.<strong>Serves 2</strong></p>
                <div className='Recepie_content'>
                    <div className='Recepie_img_part'>
                        <img className='Recepie_img' alt='none' src={kebabs} />
                        <div className='Recepie_ingredients'>
                            <h2>INGREDIENT</h2>
                            <div className='Recepie_ingredient_main'>
                                {/* <h2>Pancakes</h2> */}
                                <ul>
                                    <li>100 gms red kidney beans, boiled and
                                        mashed
                                    </li>
                                    <li>150 gm spinach, finely chopped</li>
                                    <li>1 tsp ginger-garlic paste
                                    </li>
                                    <li>1 tsp garam masala</li>
                                    <li>Salt to taste</li>
                                    <li>Wooden skewers (if desired)</li>
                                </ul>
                            </div>
                            {/* <div className='Recepie_ingredient_optional'>
                                <h2>For serving, optional</h2>
                                <ul>
                                    <li>Blueberry compote</li>
                                    <li>Sliced bananas, sliced strawberries or raspberries</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className='Recepie_non_img_part'>
                        <div className='Recepie_instructions'>
                            <h2>INSTRUCTIONS</h2>
                            <ol>
                                <li>In a large bowl, combine the mashed red
                                    kidney beans, chopped spinach, ginger-garlic
                                    paste, garam masala, and salt to taste. Mix
                                    everything well to form a smooth mixture.</li>
                                <li>Preheat the oven to 180°C (350°F).</li>
                                <li>Take small portions of the mixture and shape
                                    them into cylindrical or round kababs. If
                                    desired, you can skewer them onto wooden
                                    skewers.</li>
                                <li>Place the kababs on a baking tray lined with
                                    parchment paper and bake for 20-25 minutes
                                    or until the kababs are crispy on the outside
                                    and cooked through on the inside.</li>
                                <li>Once the kababs are done, remove them from
                                    the oven and let them cool for a few minutes
                                    before serving. You can serve these kababs
                                    with mint chutney or any other dipping sauce
                                    of your choice.
                                </li>
                                <li>Enjoy your delicious and healthy oil-free
                                    Indian style kababs made with just two
                                    ingredients!
                                </li>
                            </ol>
                        </div>
                        {/* <div className='Recepie_notes'>
                            <h2>Notes</h2>
                            <ul>
                                <li>Double the batch and freeze leftovers for a festive pancake breakfast any day of the week.</li>
                            </ul>
                        </div> */}
                        {/* <div className='Recepie_2'>
                            <h2 className='Recepie_2_name'>Blueberry compote</h2>
                            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
                            <div className='Recepie_2_ingredient'>
                                <h2>INGREDIENTS</h2>
                                <ul>
                                    <li>3 cups frozen blueberries</li>
                                    <li>2 tbsp lemon juice</li>
                                    <li>½ tsp vanilla extract</li>
                                </ul>
                            </div>
                            <div className="Recepie_2_instruction">
                                <h2>INSTRUCTIONS</h2>
                                <ol>
                                    <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                                    <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                                    <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                                    <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                                    <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
                                </ol>
                            </div>
                            <div className="Recepie_2_notes">
                                <h2>NOTES</h2>
                                <ul>
                                    <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
