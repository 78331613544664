import React, { useEffect } from 'react';
import './Faq.css';
import { Banner } from '../components/Banner';
import FaqAccordian from '../components/FaqAccordian';

export const Faq = () => {
  
  return (
    <>
      <div>
        <Banner quote={<p>These FAQs provide basic information and related resources for common food and nutrition questions</p>} title={"FAQ"}/>
        <FaqAccordian/>
      </div>
    </>
  );
};
