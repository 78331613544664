import React from 'react'
import './ContactUs.css'
import { Banner } from '../components/Banner'
import { QR_code } from '../components/QR_code'
import { FormContactUs } from '../components/FormContactUs'

export const ContactUs = () => {
  return (
    <div>
      <Banner quote={<p>Get Onboard with us. fill the form given below to get connected with us or scan the QR code to have a chat.</p>} title={"CONTACT US"}/>
      <div>
        <QR_code/>
        <FormContactUs/>
      </div>
    </div>
  )
}
