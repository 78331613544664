import img1 from '../assests/images/cards img1.png';
import img2 from '../assests/images/cards img2.jpg';


const CardsInfo = [
        {
          imageSrc: img1,
          subheading: 'NUTRITION COUNSELING',
          description: "Nutrition counseling is a personalized journey towards optimal well-being. Through thoughtful assessment, we'll collaboratively design a balanced eating plan tailored to your goals and lifestyle. By emphasizing nutrient-dense foods, portion control, and mindful eating, we aim to empower you with the knowledge and tools needed for sustained, healthier choices. Together, we'll navigate the path to a nourished body and a revitalized outlook on nutrition.",
        },

        {
          imageSrc: img2,
          subheading: 'WEIGHT-LOSS PROGRAMS',
          description: "Get Fit Mantra Believes that weightloss is not just about losing weight its about gaining a healthy lifestyle. We promote a balanced approach to weightloss that includes High quality nutrition, Nutritionist approved meal plans, lifestyle changes and fitness coaching. We offer a variety of weightloss programs to meet the needs of our clients. All of our programs includes 1:1 mentorship, free access to exclusive diet recepies, Free access to support groups, and required nutritional supplemants.",
        }
    
];
export default CardsInfo;