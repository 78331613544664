import './Recepie1.css'

import React from 'react'
import Veggie from '../assests/images/recepie_new_images/EASY VEGGIE STIR FRY.png'

export const Recepie6 = () => {
    return (
        <>
            <section className='Recepie_body'>
                <h1 className='Recepie_title'>EASY VEGGIE STIR FRY</h1>
                <p className='Recepie_intro'>Nutrient-rich stir fry made with colorful vegetables and protein-packed tempeh. This dish comes together quickly
                    for a weeknight meal and is perfect for meal prep. Feel free to switch up the vegetables based on the season.<strong>Serves 2</strong></p>
                <div className='Recepie_content'>
                    <div className='Recepie_img_part'>
                        <img className='Recepie_img' alt='none' src={Veggie} />
                        <div className='Recepie_ingredients'>
                            <h2>INGREDIENT</h2>
                            <div className='Recepie_ingredient_main'>
                                <h2>Tempeh</h2>
                                <ul>
                                    <li>1 package (8 oz) tempeh, cubed</li>
                                    <li>2 tbsp reduced-sodium soy sauce (tamari for gluten-free)</li>
                                    <li>1 tsp garlic powder </li>
                                </ul>
                            </div>
                            <div className='Recepie_ingredient_main'>
                                <h2>Stir-fry</h2>
                                <ul>
                                    <li> 2 tsp water or low-sodium vegetable
                                        broth </li>
                                    <li>1 cup chopped carrots</li>
                                    <li>1 cup chopped broccoli</li>
                                    <li>½ cup white onion, sliced </li>
                                    <li>½ cup sliced white or crimini
                                        mushrooms</li>
                                    <li>1 bell pepper, sliced</li>
                                    <li>Salt and pepper to taste</li>
                                </ul>
                            </div>
                            <div className='Recepie_ingredient_optional'>
                                <h2>For serving</h2>
                                <ul>
                                    <li>2 tbsp hoisin sauce, for serving
                                        (or another store-bought sauce of
                                        choice) </li>
                                    <li>1 cup cooked brown rice or quinoa</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='Recepie_non_img_part'>
                        <div className='Recepie_instructions'>
                            <h2>INSTRUCTIONS</h2>
                            <ol>
                                <li>In a small bowl, combine the tempeh, soy sauce, and
                                    garlic powder. Mix and set aside.</li>
                                <li>Heat a large skillet or wok over medium-high heat.
                                    Add the water or broth, carrot, broccoli, onion, and a
                                    pinch of salt. Cook for 4 to 5 minutes, stirring every 30
                                    seconds until the onions are softened.</li>
                                <li>Add the bell pepper and mushrooms and cook for 4-5
                                    minutes until everything is cooked al dente.</li>
                                <li>While the veggies are cooking, place the cubed tempeh
                                    on a baking tray and broil in the oven for 5-10 minutes
                                    until browned and crispy, stirring once halfway through.</li>
                                <li>Serve the stir-fried veggies in a bowl with cooked brown
                                    rice or quinoa, ½ of the tempeh, and 1 tbsp of hoisin
                                    sauce. </li>
                            </ol>
                        </div>
                        {/* <div className='Recepie_notes'>
                            <h2>Notes</h2>
                            <ul>
                                <li>Double the batch and freeze leftovers for a festive pancake breakfast any day of the week.</li>
                            </ul>
                        </div> */}
                        {/* <div className='Recepie_2'>
                            <h2 className='Recepie_2_name'>Blueberry compote</h2>
                            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
                            <div className='Recepie_2_ingredient'>
                                <h2>INGREDIENTS</h2>
                                <ul>
                                    <li>3 cups frozen blueberries</li>
                                    <li>2 tbsp lemon juice</li>
                                    <li>½ tsp vanilla extract</li>
                                </ul>
                            </div>
                            <div className="Recepie_2_instruction">
                                <h2>INSTRUCTIONS</h2>
                                <ol>
                                    <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                                    <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                                    <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                                    <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                                    <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
                                </ol>
                            </div>
                            <div className="Recepie_2_notes">
                                <h2>NOTES</h2>
                                <ul>
                                    <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
