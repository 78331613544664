import React, { useState } from 'react';
import './FaqAccordian.css'

const dataCollection = [
  {
    question: 'DO I NEED TO CONTINUE TO TAKE THE NUTRITIONAL SUPPLEMENTS (VITAMINS&MINERALS) FOREVER?',
    answer: 'The nutritional supplements (Vitamins & Minerals) are there to support your body during the low calorie diet. It provides all the nutrition the body requires for gut balance & detox.It is NOT recommended & safe to follow the program without these supplements.'
  }, {
    question: 'CAN I DO THE PROGRAM WHILE PREGNANT OR BREASTFEEDING?',
    answer: 'You MUST provide the product fact sheets and program to your GP/Doctor for approval before commencing. Some products may be unsuitable for you.'
  }, {
    question: 'WHY DO WE MONO EAT DURING THE PROGRAM & WHY IS IT IMPORTANT?',
    answer: <div><p>Mono eating simply means eating one type of food per serving, for e.g. One type of protein and one type of vegetable per meal. There are multiple benefits of mono eating.</p>

    <p>1. Optimal Digestion: Different foods require different digestion times. When our body tried to digest different foods that require different amounts of energy &amp; work, we lose nutrients &amp; our body works overtime to digest.</p>
    
    <p>2. Efficient Eating: Mono-meals allow us to eat until we are truly satiated.</p>
    
    <p>3. Optimal absorption of nutrients: Our body loves simplicity. When we eat simply, our bodies can take advantage of this &amp; optimally absorb nutrients.</p></div>
  }, {
    question: "WHAT IF I DON'T HAVE A LOT OF TIME TO EXERCISE?",
    answer: "If you are simply wanting to lose the bulge & weight loss, we have a program for you that you don’t even need to exercise. Our nutritionist approved program focuses on clean eating & learning how the gut works."
  }
];

function FaqAccordian() {
  const [accordion, setActiveAccordion] = useState(-1);

  function toggleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return
    }
    setActiveAccordion(index);
  };

  return (
    <>
      <div className="container">
            <div>
          {/* <span className="accordion__title">Frequently asked questions</span> */}
          <h1>Let's answer some of your questions</h1>
        </div>
        <div className="accordion__faq">
          { dataCollection.map((item, index) =>
              <div key={index} onClick={() => toggleAccordion(index)}>
                <div className="accordion__faq-heading">
                  <h2 className={accordion === index ? "active" : ""}>{item.question}</h2>
                  <div>
                    {accordion === index ?
                      <span className="verticle">-</span> : <span className="horizental">+</span>}
                  </div>
                </div>
                <div><p className={accordion === index ? "active" : "inactive"} >{item.answer}</p></div>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}

export default FaqAccordian;