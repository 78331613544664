import React from 'react'
import './AboutUs.css'
import { Banner } from '../components/Banner';
import { Profile } from '../components/Profile';
import vaishali_profile from '../assests/images/Vaishali_Shah_Pfp.jpg'
import mithlesh_profile from '../assests/images/Mithlesh_Vani_Pfp.jpg'
import JillMary_Chazhicattu from '../assests/images/JillMary_Chazhicattu_Pfp.jpg'

export const AboutUs = () => {
  return (
    <div className='body_aboutUs'>
      <Banner quote={<p>Hi there! It is nice to virtually meet you and we can’t wait to connect. Thank you for taking the time to visit our site and learn moreabout what we do and how we do it</p>} title={"ABOUT US"}/>
      <Profile name={'Vaishali Vani'} designation={"NUTRITIONIST AND FITNESS COACH"} imgshow_left={false} img_src={vaishali_profile} intro={<div><p>Hello and welcome! I'm thrilled to have you visit my website. As an ISSA Certified Nutritionist, affiliated Gut Health/Weight Loss Coach, Certified Fitness Coach and Licensed Physical Therapist Assistant, I specialize in 1:1 consultations for personalized weight loss through lifestyle changes and high-quality nutrition.</p><p>With a deep understanding of the mind-body connection, my holistic approach, blending expertise in gut health, physical therapy, aims to promote digestive wellness, physical vitality, and mental clarity.</p></div>}/>
      <Profile name={'Mithlesh Vani'} designation={"LIFESTYLE COACH AND PHYSICAL THERAPIST"} imgshow_left={true} img_src={mithlesh_profile} intro={<div><p>Hi! As a dedicated Lifestyle Medicine Practitioner, licensed Physical Therapist, and experienced Weight-Loss Mentor, I'm committed to help clients achieve health and wellness goals. My personalized guidance focuses on enhancing physical fitness, promoting mental well-being, and facilitating sustainable weight loss.</p><p>Utilizing a holistic approach, I empower individuals through exercise, stress and sleep management, nutrition, and positive mindset shifts. Let's embark on a transformative journey to optimal well-being together.</p></div>}/>
      <Profile name={'JillMary Chazhicattu'} designation={'MS Occupational Therapist/Licensed, Certified Functional Nutrition Informed Practitioner'} img_src={JillMary_Chazhicattu} intro={'Introducing JillMary Chazhicattu, MS, a seasoned Occupational Therapist with a passion for holistic well-being. With a background in Occupational Therapy and certification as a Functional Nutrition Informed Practitioner, JillMary brings a unique blend of expertise to her practice. Her dedication to empowering individuals to achieve optimal health through personalized nutrition and lifestyle interventions sets her apart in the field. With a focus on addressing the root causes of health concerns and promoting holistic wellness, JillMary is committed to guiding her clients on a journey towards vibrant health and vitality.'}/>
      <div className='our_mission'>
        <h1>OUR MISSION</h1>
        <p>In the realm of health and wellness, Vaishali & Mithlesh are beacons of hope, a catalyst for change, and a source of unwavering support. Their unique blend of expertise, empathy, and passion make them a trusted mentor and an invaluable asset to all those who seek her guidance. With their compassionate guidance, countless individuals have transformed their lives, embracing health, vitality, and a renewed zest for life.</p>
        <div className='parts'>
          <div className='part1'>
            <p>Their mission is clear: to empower others to take control of their health, cultivate a strong body and mind, and embrace a life filled with wellness and fulfillment. Through her unwavering commitment, she continues to make a lasting impact on the lives of others, inspiring them to unleash their full potential and embrace a life of optimal well-being.</p>
          </div>
          <div className='part2'>
            <p>They have honed their expertise in assisting patients on their path to recovery and rehabilitation. Through their compassionate approach and specialized knowledge, they have helped countless individuals regain strength, mobility, and independence. Their commitment to improving the lives of others is evident in the progress and successes of their patients.</p>
          </div>
        </div>
      </div>
    
    </div>
  )
}
