import './Recepie1.css'

import React from 'react'
import CabbageSoup from '../assests/images/recepie_new_images/cabbage soup out.jpg'

export const Recepie7 = () => {
    return (
        <>
            <section className='Recepie_body'>
                <h1 className='Recepie_title'>VEGGIE CABAGE SOUP</h1>
                <p className='Recepie_intro'>Loaded with cabbage, carrots, bell pepper and tomato, this healthy cabbage soup recipe packs in lots of flavor and is ultra-satisfying. This easy recipe makes a big batch for lunch or dinner all week.<strong>Serves 1</strong></p>
                <div className='Recepie_content'>
                    <div className='Recepie_img_part'>
                        <img className='Recepie_img' alt='none' src={CabbageSoup} />
                        <div className='Recepie_ingredients'>
                            <h2>INGREDIENT</h2>
                            <div className='Recepie_ingredient_main'>
                                {/* <h2>Pancakes</h2> */}
                                <ul>
                                    <li>2 tablespoons extra-virgin olive oil</li>
                                    <li>1 medium onion, chopped</li>
                                    <li>2 medium carrots, chopped</li>
                                    <li>2 stalks celery, chopped</li>
                                    <li>1 medium red bell pepper, chopped</li>
                                    <li>2 cloves garlic, minced</li>
                                    <li>3/2 teaspoons Italian seasoning</li>
                                    <li>1/2 teaspoon ground pepper</li>
                                    <li>1/4 teaspoon salt</li>
                                    <li>8 cups low-sodium vegetable broth</li>
                                    <li>1 medium head green cabbage, halved and sliced</li>
                                    <li>1 large tomato, chopped</li>
                                    <li>2 teaspoons white-wine vinegar</li>
                                </ul>
                            </div>
                            {/* <div className='Recepie_ingredient_optional'>
              <h2>For serving, optional</h2>
              <ul>
                <li>Blueberry compote</li>
                <li>Sliced bananas, sliced strawberries or raspberries</li>
              </ul>
            </div> */}
                        </div>
                    </div>
                    <div className='Recepie_non_img_part'>
                        <div className='Recepie_instructions'>
                            <h2>INSTRUCTIONS</h2>
                            <ol>
                                <li>Heat oil in a large pot over medium heat.</li>
                                <li>Add onion, carrots and celery.</li>
                                <li>Cook, stirring, until the vegetables begin to soften, 6 to 8 minutes.</li>
                                <li>Add bell pepper, garlic, Italian seasoning, pepper and salt and cook, stirring, for 2 minutes.</li>
                                <li>Add broth, cabbage and tomato; increase heat to medium-high and bring to a boil.</li>
                                <li>Reduce heat to maintain a simmer, partially cover and cook until all the vegetables are tender, 15 to 20 minutes more.</li>
                                <li>Remove from heat and stir in vinegar.</li>
                            </ol>
                        </div>
                        {/* <div className='Recepie_notes'>
            <h2>Notes</h2>
            <ul>
              <li>Double the batch and freeze leftovers for a festive pancake breakfast any day of the week.</li>
            </ul>
          </div> */}
                        {/* <div className='Recepie_2'>
                            <h2 className='Recepie_2_name'>Blueberry compote</h2>
                            <p>This healthy blueberry compote is naturally sweet and brusting with fresh blueberry flavor! All you need is 3 ingredient and 15 minutes to make this antioxidant-packed treat.</p>
                            <div className='Recepie_2_ingredient'>
                                <h2>INGREDIENTS</h2>
                                <ul>
                                    <li>3 cups frozen blueberries</li>
                                    <li>2 tbsp lemon juice</li>
                                    <li>½ tsp vanilla extract</li>
                                </ul>
                            </div>
                            <div className="Recepie_2_instruction">
                                <h2>INSTRUCTIONS</h2>
                                <ol>
                                    <li>Place the blueberries, lemon juice, and vanilla extract in a small saucepan and over medium-high heat.</li>
                                    <li>Bring to a boil, then reduce heat to a medium-low and use a wooden spoon to mash the fruit.</li>
                                    <li>Continue cooking for 10 to 15 minutes, stirring intermittently.</li>
                                    <li>Remove from heat and set aside to cool. The compote will thicken as it cools.</li>
                                    <li>Reheat to serve with pancakes, oatmeal, waffles, and more.</li>
                                </ol>
                            </div>
                            <div className="Recepie_2_notes">
                                <h2>NOTES</h2>
                                <ul>
                                    <li>Citrus option: Orange juice or lime juice will also work. Feel free to experiment with other frozen berries! Rasberries, strawberries, and cherries work great as well.</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
