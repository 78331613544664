import React from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import "./App.css";
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import { OurPrograms } from './pages/OurPrograms';
import { AboutUs } from './pages/AboutUs';
import { Recepies } from './pages/Recepies';
import { SuccessStories } from './pages/SuccessStories';
import { Faq } from './pages/Faq';
import { ContactUs } from './pages/ContactUs';
import Footer from './components/Footer';
import { Recepie1 } from './pages/Recepie1';
import { Recepie2 } from './pages/Recepie2';
import { Recepie3 } from './pages/Recepie3';
import { Recepie4 } from './pages/Recepie4';
import { Recepie5 } from './pages/Recepie5';
import { Recepie6 } from './pages/Recepie6';
import { Recepie7 } from './pages/Recepie7';
import { Recepie8 } from './pages/Recepie8';
import { Recepie9 } from './pages/Recepie9';

const App = () => {
  return (
    <>
    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/ourPrograms' element={<OurPrograms/>}/>
        <Route path='/aboutUs' element={<AboutUs/>}/>
        <Route path='/recepies' element={<Recepies/>}/>
        <Route path='/successStories' element={<SuccessStories/>}/>
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/contactUs' element={<ContactUs/>}/>
        <Route path='/recepie1' element={<Recepie1/>}/>
        <Route path='/recepie2' element={<Recepie2/>}/>
        <Route path='/recepie3' element={<Recepie3/>}/>
        <Route path='/recepie4' element={<Recepie4/>}/>
        <Route path='/recepie5' element={<Recepie5/>}/>
        <Route path='/recepie6' element={<Recepie6/>}/>
        <Route path='/recepie7' element={<Recepie7/>}/>
        <Route path='/recepie8' element={<Recepie8/>}/>
        <Route path='/recepie9' element={<Recepie9/>}/>
      </Routes>
    <Footer/>
    </BrowserRouter>
      {/* <Router basename=''>
        <Navbar />
        <Routes> 
          <Route path="/" element={<Home />} />
        </Routes>
      </Router> */}
    </>
  );
};

export default App;
