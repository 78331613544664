import './QR_code.css'
import whatsapp_qr from '../assests/images/whatsapp_qr.png'

import React from 'react'

export const QR_code = () => {
  return (
    <div className='Qr_wrapper'>
        <div className='Qr_text_container'>
            <h1>Scan the QR Code to Chat with us directly on Whatsapp</h1>
        </div>
        <div className='Qr_image_container'>
            <img className='Qr_image' src={whatsapp_qr}/>
        </div>
    </div>
  )
}
